import React, {Component} from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import AWS from 'aws-sdk'
import { downloadZip } from 'client-zip'

import {
  getPlaylistTracks,
  filterPlaylistTracks,
  removeTrackFromPlaylist,
  removeTracksFromPlaylist,
  changeDesignation,
  changeDesignations,
  addTrackToQueue,
  removeDuplicates,
  addSelectedTrack,
  clearSelectedPlaylistTracks,
  removeSelectedTrack,
  setPlaylistTracksSorted
} from 'store/actions/playlistAction'

import {
  getGenres,
  getTrack
} from 'store/actions/libraryAction'

import { toggleConfirm } from 'store/actions/confirmAction'

import { getTrackUrl, getTrackInfo } from 'store/actions/playerAction'
import { toggleOverlay } from 'store/actions/overlayAction'
import { getQueue } from 'helpers/QueueHelper'

import Confirm from 'ui/Confirm'
import Container from 'ui/Container'
import Loader from 'ui/Loader'
import PlaylistTracksTableAction from './playlist-tracks/PlaylistTracksTableAction'
import PlaylistTracksRow from './playlist-tracks/PlaylistTracksRow'

import TableWrapper from 'ui/TableWrapper'
import PlaylistTracksTableRowActions from './playlist-tracks/PlaylistTracksTableRowActions'

import { debounce } from 'helpers/Debounce'
import { tableSorter } from 'helpers/TableHelper'

const classname = 'playlistTracks'

AWS.config.update({
  accessKeyId: 'AKIARLNPHEHHWZ327KQC',
  secretAccessKey: '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',
  region: 'eu-west-1'
})

const s3 = new AWS.S3()

class PlaylistTracks extends Component {

  constructor(props){
    super(props)
    this.state = {
      filter:null,
      actionActive:false
    }
    this.filterTracksDispatch = debounce(this.filterTracksDispatch,300)

    this.S3config =
      {
        bucketName: 'oe-media-raw.openearmusic.com',
        s3Url: 'https://s3-eu-west-1.amazonaws.com/oe-media-raw.openearmusic.com'
      }
  }

  componentDidMount(){
    const {
      dispatch,
      selectedTracks
    } = this.props

    dispatch(getPlaylistTracks(this.props.id))
    dispatch(getGenres())
    //clear filter on mount
    this.filterTracksDispatch('')

    // if the component loads with tracks already selected,
    // show the table actions
    if(selectedTracks.length > 0){
      this.setState({
        actionActive:true
      })
    }
  }

  componentDidUpdate(prevProps,prevState){
    const {
      dispatch,
      id,
      tracks,
      filter,
      removeDuplicatesLoading,
      selectedTracks,
      sorter
    } = this.props

    if(prevProps.tracks !== tracks){
      this.setTracks()
    }
    //filter
    if(prevProps.filter !== filter){
      this.setTracks()
    }
    if(prevProps.removeDuplicatesLoading !== removeDuplicatesLoading && !removeDuplicatesLoading){
      dispatch(getPlaylistTracks(id))
    }

    if (prevProps.selectedTracks !== selectedTracks) {
      this.setState({
        actionActive: selectedTracks.length > 0
      })
    }

    if (JSON.stringify(prevProps.sorter) !== JSON.stringify(sorter)) {
      this.setTracks()
    }
  }

  playTrack(track){
    const {
      dispatch
    } = this.props

    dispatch(getTrackInfo(track, 'playlist'))
    dispatch(getTrackUrl(track, 'playlist '))
  }

  filterTracks(e){
    const filter = e.target.value
    this.filterTracksDispatch(filter)
  }

  filterTracksDispatch(term){
    this.props.dispatch(filterPlaylistTracks(term))
  }

  moreActions(data){
    this.props.dispatch(toggleOverlay(true,data,'playlistTracksMore'))
  }

  sortType(x,y){
    const {field, direction} = this.props.sorter

    // if no direction is provided, due to the sorting icon being reset,
    // or there is no value provided to be sorted on, use default values
    if (direction === null || !(field in x)) {
      return tableSorter(x,y,'added','desc')
    }

    return tableSorter(x,y,field,direction)
  }

  setTracks(){
    const {
      dispatch,
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const tracksArray = [...tracks]

    //sort tracks by date added to playlist and filter
    const sortedTracks = tracksArray
      .sort((x, y) => this.sortType(x, y))
      .filter(track => {
        if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
          return track
        }
        return false
      })

    dispatch(setPlaylistTracksSorted(sortedTracks))
  }

  removeTrack(data){
    this.props.dispatch(removeTrackFromPlaylist(data))
  }

  checkboxAction(e,data,index){
    const {
      dispatch
    } = this.props

    if(e.nativeEvent.metaKey || e.nativeEvent.shiftKey){
      return
    }
    if(e.target.checked){
      data.index = index
      dispatch(addSelectedTrack(data))
    } else {
      dispatch(removeSelectedTrack(data))
    }
  }

  clickRow(e,data,isChecked,index){
    const {
      dispatch,
      selectedTracks,
      sortedTracks
    } = this.props

    e.stopPropagation()
    //cmd + click
    if(e.metaKey){
      if(!isChecked){
        data.index = index
        dispatch(addSelectedTrack(data))
      } else {
        dispatch(removeSelectedTrack(data))
      }
      if(selectedTracks.length > 0){
        this.setState({
          actionActive:true
        })
      } else {
        this.setState({
          actionActive:false
        })
      }
    }
    if(e.shiftKey){
      if(selectedTracks.length === 1){
        for (let i = selectedTracks[0].index + 1; i <= index; i++) {
          dispatch(addSelectedTrack(sortedTracks[i]))
        }
        this.setState({
          actionActive:true
        })
      }
    }
  }

  deselectTracks(){
    this.props.dispatch(clearSelectedPlaylistTracks())
    this.setState({
      actionActive:false
    })
  }

  selectAll(selected){
    const {
      dispatch,
      filter,
      tracks
    } = this.props

    // create new array, so that we do not mutate the props data
    // allows proper reset of sorting order
    const sortedTracks = [...tracks]

    //sort tracks by date added to playlist and filter
    if(selected){
      sortedTracks
        .sort((x, y) => this.sortType(x, y))
        .filter(track => {
          if (track.filter && track.filter.toLowerCase().includes(filter.toLowerCase())) {
            dispatch(addSelectedTrack(track))
          }
          return false
        })
        this.setState({
          actionActive:true
        })
    } else {
      dispatch(clearSelectedPlaylistTracks())
      this.setState({
        actionActive:false
      })
    }
  }

  getBreakdown(data){
    this.props.dispatch(toggleOverlay(true,data,'breakdown'))
  }

  removeTracks(){
    const {
      dispatch,
      selectedTracks
    } = this.props

    const confirmData = {
      action: () => {
        dispatch(removeTracksFromPlaylist(selectedTracks));
        this.deselectTracks()
      },
      question: `Permanently remove selected tracks from playlist?`
    }

    dispatch(toggleConfirm(true, confirmData))
  }

  addTracksToPlaylist(){
    this.props.dispatch(toggleOverlay(true,this.props.selectedTracks,'multiPlaylistsAdd'))
  }

  editTracks(){
    this.props.dispatch(toggleOverlay(true,this.props.selectedTracks,'multiEditTracks'))
  }

  openPlaylistOverlay(track){
    this.props.dispatch(toggleOverlay(true,{track, playlistID: this.props.id},'playlistsAdd'))
  }

  changeDesignation(data){
    //get new split id
    const newSplitID = this.props.metadata[data.event.target.value].playlist_id
    const track = data.data
    const send = {
      track_id:track.id,
      curr_split_id:track.split_id,
      parent_id:track.parent_id,
      new_split_id:newSplitID,
      designation:data.event.target.value
    }
    this.props.dispatch(changeDesignation(send))
  }

  changeDesignations(data){
    //get new split id
    const newSplitID = this.props.metadata[data.event.target.value].playlist_id
    this.props.dispatch(changeDesignations(this.props.selectedTracks.map(track => {
      return {
        track_id:track.id,
        curr_split_id:track.split_id,
        parent_id:track.parent_id,
        new_split_id:newSplitID,
        designation:data.event.target.value
      }
    })))
    this.deselectTracks()
  }

  addTrackToQueue(data){
    this.props.dispatch(addTrackToQueue(data.track))
    getQueue()
  }

  favouriteTrack(data){
    console.log(data)
  }

  editTrack(track){
    this.props.dispatch(getTrack(track.id))
    this.props.dispatch(toggleOverlay(true,null,'editTrack'))
  }

  libraryAction(data,e){
    if(e && e.metaKey){
      e.stopPropagation()
      window.open(data,'_blank')
    } else {
      this.props.history.push(`${this.props.match.url}${data}`)
    }
  }

  removeDuplicates(){
    this.props.dispatch(removeDuplicates(this.props.id))
  }

  downloadSelectedTracks(){
    this.setState({
      downloading:true
    })
    const fileNames = this.props.selectedTracks.map(track => track.filename)
    this.zipAndDownloadTracks(fileNames)
  }

  async getSignedUrl(key, expires = 600){
    const params = {
        Bucket: this.S3config.bucketName,
        Key: key,
        Expires: expires // Time in seconds until the signed URL expires
    };

    return new Promise((resolve, reject) => {
      s3.getSignedUrl('getObject', params, (err, url) => {
        if (err) {
          reject(err);
        } else {
          resolve(url);
        }
      })
    })
  }

  async zipAndDownloadTracks(fileNames){
    const files = await Promise.all(fileNames.map(async (fileName) => {
      const url = await this.getSignedUrl(fileName)
      const response = await fetch(url);
      return response;
    }))

    const blob = await downloadZip(files).blob()

    // make and click a temporary link to download the Blob
    const link = document.createElement("a")
    link.href = URL.createObjectURL(blob)
    link.download = `${encodeURIComponent(this.props.playlistName + Date.now())}.zip`
    link.click()
    link.remove()
    this.setState({
      downloading:false
    })
  }

  getTable(){
    const {
      loading,
      metadata,
      openDesignation,
      playlistName,
      playingTrack,
      tracks,
      selectedTracks,
      sortedTracks,
      sorter
    } = this.props

    return <TableWrapper
      classname={classname}
      data={sortedTracks}
      loading={loading}
      selectAll={(selected)=>this.selectAll(selected)}
      rowComp={
        <PlaylistTracksRow
          play={(track)=>this.playTrack(track)}
          playingTrack={playingTrack}
          checkboxSelected={selectedTracks}
          checkboxAction={(e,data,index)=>this.checkboxAction(e,data,index)}
          clickRow={(e,data,isChecked,index)=>this.clickRow(e,data,isChecked,index)}
          libraryAction={(data,e) => this.libraryAction(data,e)}
          />
      }
      rowActions={
        <PlaylistTracksTableRowActions
          favouriteTrack={(data)=>this.favouriteTrack(data)}
          moreActions={(data)=>this.moreActions(data)}
          removeTrack={(data)=>this.removeTrack(data)}
          openPlaylistOverlay={(data)=>this.openPlaylistOverlay(data)}
          changeDesignation={(data)=>{this.changeDesignation(data)}}
          addTrackToQueue={(data)=>{this.addTrackToQueue(data)}}
          editTrack={(track) => this.editTrack(track)}
          classname={classname}
        />
      }
      tableAction={<PlaylistTracksTableAction
        downloading={this.state.downloading}
        downloadSelectedTracks={()=>this.downloadSelectedTracks()}
        removeDuplicates={()=>this.removeDuplicates()}
        active={this.state.actionActive}
        classname={classname}
        metadata={metadata}
        count={tracks.length}
        placeholder="Filter tracks..."
        numSelected={selectedTracks.length}
        action={(e)=>this.filterTracks(e)}
        changeInfo={()=>this.editTracks()}
        addTrackToQueue={(data)=>this.addTrackToQueue(data)}
        deselectTracks={()=>this.deselectTracks()}
        removeTracks={()=>this.removeTracks()}
        addTracksToPlaylist={()=>this.addTracksToPlaylist()}
        getBreakdown={(data)=>this.getBreakdown(data)}
        openDesignation={(designation,id)=>openDesignation(designation,id)}
        CSVData={this.getCSVData()}
        CSVFileName={`${playlistName}--tracks`}
        changeDesignations={(split)=>this.changeDesignations(split)}/>
      }
      />
  }

  getCSVData() {
    const {
      tracks
    } = this.props

    let csvDataArray = []

    tracks.map((track) => {
      // escape double quotes in the csv file
      const trackData = {
        artist: track.artist ? track.artist.replace(/"/g, '""') : 'MISSING ARTIST INFO',
        title: track.title ? track.title.replace(/"/g, '""') : 'MISSING TRACK INFO'
      }

      csvDataArray.push(trackData)
      return false
    })

    return csvDataArray
  }

  render(){
    if(this.props.loading){
      return <Loader/>
    } else {
      return (
        <Container classname="playlist-tracks" height="100%" column>
          {this.getTable()}
          <Confirm />
        </Container>
      )
    }
  }
}
function mapStateToProps(store){
  return {
    tracks:store.playlist.tracks,
    sortedTracks:store.playlist.sortedTracks,
    sorter:store.table.sorter,
    selectedTracks:store.playlist.selectedTracks,
    metadata:store.playlist.metadata,
    filter:store.playlist.filter,
    loading:store.playlist.loading,
    playingTrack: store.player.track,
    removeDuplicatesLoading:store.playlist.removeDuplicatesLoading
  }
}

export default withRouter(connect(mapStateToProps)(PlaylistTracks))
